import React from 'react';

function Customers() {
	return (
		<>
		Customers page
		</>
	)
}

export default Customers;